import { RouterProvider } from 'react-router-dom';

// routing
import router from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
import Notistack from 'ui-component/third-party/Notistack';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider as AuthContextProvider } from './contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => {
    return (
        <ThemeCustomization>
            {/* RTL layout */}
            {/* <RTLLayout> */}

            <Locales>
                <NavigationScroll>
                    <Auth0Provider
                        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
                        clientId={process.env.REACT_APP_CLIENT_ID || ''}
                        authorizationParams={{
                            redirect_uri: window.location.origin,
                            audience: process.env.REACT_APP_AUDIENCE
                        }}
                    >
                        <AuthContextProvider>
                            <Notistack>
                                <RouterProvider router={router} />
                                <Snackbar />
                            </Notistack>
                        </AuthContextProvider>
                    </Auth0Provider>
                </NavigationScroll>
            </Locales>
            {/* </RTLLayout> */}
        </ThemeCustomization>
    );
};

export default App;
