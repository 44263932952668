import { createRoot } from 'react-dom/client';

// third party
import { Provider } from 'react-redux';

// project imports
import App from 'App';
import { store } from 'store';
import * as serviceWorker from 'serviceWorker';
import reportWebVitals from 'reportWebVitals';
// import { ConfigProvider } from 'contexts/ConfigContext';

// style + assets
import 'assets/scss/style.scss';

// google fonts
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/700.css';

import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
// import React from 'react';
// import { BrowserRouter } from 'react-router-dom';
// import { Auth0Provider } from '@auth0/auth0-react';


// ==============================|| REACT DOM RENDER ||============================== //

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        {/* <ConfigProvider> */}
        <App />
        {/* </ConfigProvider> */}
    </Provider>
    // // <React.StrictMode>
    // //     {/* <BrowserRouter>   */}
    // //         <Provider store={store}>
    // //             <App />
    // //         </Provider>
    //         <Auth0Provider
    //         domain="dev-8gj34ag3oolo83ev.us.auth0.com"
    //         clientId="2IxDWARbGREtQjO0FKVx4IiaF1D9izLj"
    //         authorizationParams={{
    //             redirect_uri: window.location.origin
    //         }}
    //         >
    //         {/* <LoginButton/> */}
    //         <Provider store={store}>
    //     {/* <ConfigProvider> */}
    //             <App />
    //     {/* </ConfigProvider> */}
    //         </Provider>
    //         </Auth0Provider>,
    //     {/* </BrowserRouter> */}
    // </React.StrictMode>

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
