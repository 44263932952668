// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconDashboard } from '@tabler/icons-react';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconBrandChrome,
    IconDashboard
};
const samplePage: NavItemType = {
    id: 'sample-page',
    title: <FormattedMessage id="sample-page" />,
    icon: icons.IconDashboard,
    type: 'group',
    url: '/sample-page'
};

export default samplePage;
