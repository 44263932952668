import { Button } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { usePlaidLink, PlaidLinkOnSuccess } from 'react-plaid-link';
import axios from 'utils/axios';


const SimplePlaidLink = () => {
  const [token, setToken] = useState<string | null>(null);

  // get link_token from your server when component mounts
  React.useEffect(() => {
    console.log('simple link component mounted');
    const createLinkToken = async () => {
        console.log('setting token');
        const options = {
            headers: {
                'content-type': 'application/json'
            }
        };

        try {
            const response = await axios.post('/api/plaid/linktoken', {}, options);
            // console.log();
            const {linkToken} = response.data;
    
            setToken(linkToken);
        }catch (err) {
            
        }
    };
    createLinkToken();
  }, []);

  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    // send public_token to your server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    // console.log('now have public token');
    // console.log(publicToken, metadata);
  }, []);

  const { open, } = usePlaidLink({
    token,
    onSuccess,
    // onEvent
    // onExit
  });

  return (
    <>
    <Button variant="contained" onClick={() => open()}>Add Account</Button>
    </>
  );
};

export default SimplePlaidLink;