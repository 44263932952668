import React, { createContext, useEffect, useReducer } from 'react';

// third-party
// import { Auth0Client } from '@auth0/auth0-spa-js';
import { useAuth0 } from "@auth0/auth0-react";

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
// import axios from 'utils/axios';

// types
import { KeyedObject } from 'types';
import { Auth0ContextType, InitialLoginContextProps } from 'types/auth';

const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// ==============================|| AUTH0 CONTEXT & PROVIDER ||============================== //

const Auth0Context = createContext<Auth0ContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const {user, logout, isAuthenticated } = useAuth0();

    if(isAuthenticated) {
        console.log('User is authenticated: ' + user?.email);
        // initialState.user = user;
        initialState.user = {};
        initialState.isLoggedIn = true;
        initialState.user.email = user?.email;
        initialState.user.name = user?.name;
    }

    useEffect(() => {
        const init = async () => {
            try {
                if (isAuthenticated) {
                    // const getNewToken = async () => {
                    //     let token = await getAccessTokenSilently();
                    //     axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                    // }
                    
                    // await getNewToken();
                    

                    initialState.user = user;
                    initialState.isLoggedIn = true;
                    console.log('authenticated successfully!' + user);
                    
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                id: user?.sub,
                                email: user?.email
                            }
                        }
                    });
                } else {
                    console.log('in not authenticated');
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (options?: KeyedObject) => {
        console.log('trying to login');
        if (isAuthenticated) {
            console.log('authenticated successfuly - login' + user);
            // const user = await auth0Client.getUser();

            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: {
                        id: user?.sub,
                        avatar: user?.picture,
                        email: user?.email,
                        name: user?.name,
                        tier: 'Premium'
                    }
                }
            });
        }
    };

    const userLogout = () => {
        console.log('trying to logout');
        logout({
            logoutParams: {returnTo: window.location.origin}
        });

        dispatch({
            type: LOGOUT
        });
    };

    const resetPassword = async (email: string) => {};

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return <Auth0Context.Provider value={{ ...state, login, userLogout, resetPassword, updateProfile }}>{children}</Auth0Context.Provider>;
};

export default Auth0Context;
