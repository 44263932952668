import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
// import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types';
import { useAuth0 } from "@auth0/auth0-react";
// import { is } from 'immer/dist/internal';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const {isAuthenticated } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        // console.log('in the authguard');
        if (!isAuthenticated) {
            navigate('login', { replace: true });
        }
    }, [isAuthenticated, navigate]);

    return children;
};

export default AuthGuard;
